import { AppContainer } from 'containers';
import { IS_EPAYZ } from 'env';
import { LanguageProvider } from 'i18n';
import { default as jwtDecode } from 'jwt-decode';
import { PrivateLayout } from 'layouts';
import { useEffect, useState, useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers';
import { signIn } from 'reducers/profile';
import { epayzRoute, publicRoute, staticRoute } from 'routes';
import ApiService, {IApiService} from './services/ApiService';
import AuthService, {IAuthService} from './services/AuthService';
import { User } from 'oidc-client';
const authService: IAuthService = new AuthService();
const apiService: IApiService = new ApiService(authService);

const App = () => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    try {
      
      const profile = JSON.parse(localStorage.getItem('profile') ?? '');
      jwtDecode(profile.accessToken);
      store.dispatch(signIn(profile));
      
    
    } catch {

    } finally {
      setIsReady(true);
    }
  }, []);


  // useEffect(() => {
  //   debugger;
  //   console.log(window.location)
  //   const serviceGetUser = async () => {
  //     const userResponse = await authService.getUser();
  //     if (userResponse) {
  //       //console.log(userResponse)
  //       //const profile = JSON.parse(localStorage.getItem('profile') ?? '');
  //       //(profile.accessToken);
  //       //jwtDecode(userResponse.access_token);
  //       //store.dispatch(signIn(userResponse));
  //     } 
  //     else if (window.location.pathname == "/signin-callback"){
  //       await authService.signinCallback();
  //     }
  //     else {
  //         authService.login();
  //     }
  //     setIsReady(true);
  //     return userResponse;
  //   };

  //   serviceGetUser();
  // }, [authService]);


  // const serviceGetUser = useCallback(async () => {
  //   const userResponse = await authService.getUser();
  //     setUser(userResponse);
  //     return userResponse;
  // }, [authService]);

  // const callApi = () => {
  //   apiService
  //     .callApi()
  //     .then(data => {
  //       setApi(data.data);
  //       console.log('Api returned data.');
  //       //toastr.success('Api returned data.');
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  // const renewToken = () => {
  //   authService
  //     .renewToken()
  //     .then(user => {
  //       console.log('Token has been renewed.');
  //       //toastr.success('Token has been renewed.');
  //       serviceGetUser();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       //toastr.error(error);
  //     });
  // };

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          <AppContainer>
            {isReady && (
              <Routes>
                {Object.values(IS_EPAYZ ? epayzRoute : staticRoute).map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                {Object.values(publicRoute).map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                <Route path='/*' element={<PrivateLayout />} />
              </Routes>
            )}
          </AppContainer>
        </LanguageProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default App;
