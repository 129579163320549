import { Chip, Collapse, Divider, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { usePermission } from 'hooks';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { privateRoute } from 'routes';

type SubMenuType = {
  name?: string | JSX.Element;
  path: string;
  requiredPermission?: boolean;
};

type MenuType = {
  name?: string | JSX.Element;
  path: string;
  items?: SubMenuType[];

};

const MenuItem = ({ name, path, items }: MenuType) => {
  const location = useLocation();
  const [open, setOpen] = useState(location.pathname.startsWith(path));
  return (
    <>
      {items ? (
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
        </ListItemButton>
      ) : (
        <ListItemButton component={Link} to={path} selected={location.pathname.startsWith(path)}>
          <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
        </ListItemButton>
      )}

      <Collapse in={open}>
        <List className='ml-3' dense disablePadding>
          {items?.filter(x => x.requiredPermission).map((sub, index) => (
            <MenuItem key={index} {...sub} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const Menu = () => {
  const { role, position, fullname } = useSelector(profileSelector);
  const { requreUserPermission, requireRole, requireUserPC, requireUserPCLeader, isAccountantRoles, ENTERPRISE_VIEW, EMPLOYEE_VIEW, ENTERPRISE_SETUP_VIEW, EMPLOYEE_SETUP_VIEW } = usePermission();

  const { home } = privateRoute;
  const { client, enterpriseClient, employeeClient } = privateRoute;
  const { clientSetup, enterpriseSetup, employeeSetup } = privateRoute;
  const { department, departmentAssignment, departmentDigitalSigning } = privateRoute;
  const { account, accountEmployee, accountEnterprise, accountInformation } = privateRoute;
  const { generalSetup, costAndPriceSetup, promotionSetup, creditScoreSetup } = privateRoute;

  const { attendanceManagement, entContractManagement, hrManagement, enterpriseNotices, transactionEnterpriseHis } = privateRoute;
  const {
    transactionManagement,
    transactionPaying,
    transactionSettlement,
    transactionReject,
    transactionOverdue,
    transactionHis,
  } = privateRoute;
  const { empContractManagement, payrollApplication, transactionHistory } = privateRoute;
  const { enterpriseServiceFee } = privateRoute;
  checkMenuDoanhNghiep();
  function checkMenuDoanhNghiep() {
    enterpriseClient.requiredPermission = requreUserPermission([ENTERPRISE_VIEW]);
    enterpriseSetup.requiredPermission = requreUserPermission([ENTERPRISE_SETUP_VIEW]);
  }
  checkMenuNLD();
  function checkMenuNLD() {
    employeeClient.requiredPermission = requreUserPermission([EMPLOYEE_VIEW]);
    employeeSetup.requiredPermission = requreUserPermission([EMPLOYEE_SETUP_VIEW]);
  }
  return (
    <>
      {requireUserPC() && (
        <List component={Paper} className='w-60'>
          <div className='flex flex-col items-start gap-2 px-4 pb-2'>
            <Typography variant='h4'>{fullname}</Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              {position}
            </Typography>
            <Chip label={role?.name} />
            <Divider className='w-full' />
          </div>
          <MenuItem {...home} />
          {!isAccountantRoles() && (requreUserPermission([ENTERPRISE_VIEW]) || requreUserPermission([EMPLOYEE_VIEW])) && <MenuItem {...client} items={[enterpriseClient, employeeClient]} />}
          {!isAccountantRoles() && (requreUserPermission([ENTERPRISE_SETUP_VIEW]) || requreUserPermission([EMPLOYEE_SETUP_VIEW])) && <MenuItem {...clientSetup} items={[enterpriseSetup, employeeSetup]} />}
          {(requireUserPCLeader() || isAccountantRoles() || requireRole("SUPPORTER")) && <MenuItem {...enterpriseServiceFee} />}
          <MenuItem {...transactionManagement} items={[transactionPaying, transactionSettlement, transactionReject]} />
          <MenuItem {...transactionOverdue} />
          <MenuItem {...department} items={[departmentAssignment, departmentDigitalSigning]} />
          <MenuItem {...generalSetup} items={[costAndPriceSetup, promotionSetup, creditScoreSetup]} />
          <MenuItem {...transactionHis} />
          <MenuItem {...account} items={[accountEnterprise, accountInformation]} />
        </List>
      )}
      {requireRole('ENTERPRISE', false) && (
        <List component={Paper} className='w-60'>
          <div className='flex flex-col items-start gap-2 px-4 pb-2'>
            <Typography variant='h4'>{fullname}</Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              {position}
            </Typography>
            <Chip label={role?.name} />
            <Divider className='w-full' />
          </div>
          <MenuItem {...home} />
          <MenuItem {...hrManagement} />
          <MenuItem {...attendanceManagement} />
          <MenuItem {...transactionManagement} items={[transactionEnterpriseHis]} />
          <MenuItem {...entContractManagement} />
          <MenuItem {...enterpriseNotices} />
          <MenuItem {...account} items={[accountEnterprise, accountInformation]} />
        </List>
      )}
      {requireRole('EMPLOYEE', false) && (
        <List component={Paper} className='w-60'>
          <div className='flex flex-col items-start gap-2 px-4 pb-2'>
            <Typography variant='h4'>{fullname}</Typography>
            <Typography variant='subtitle2' color='textSecondary'>
              {position}
            </Typography>
            <Chip label={role?.name} />
            <Divider className='w-full' />
          </div>
          <MenuItem {...home} />
          <MenuItem {...payrollApplication} />
          <MenuItem {...transactionHistory} />
          <MenuItem {...empContractManagement} />
          <MenuItem {...account} items={[accountEmployee, accountInformation]} />
        </List>
      )}
    </>
  );
};

export default Menu;
