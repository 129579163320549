import { Trans } from '@lingui/macro';
import { Fragment,useEffect,useState } from 'react';
import { AccountEmployee, AccountEnterprise, AccountInformation } from 'views/Account';
import { EmployeeSetupList, EmployeeSetupView } from 'views/ClientSetup/Employee';
import { EnterpriseSetupList, EnterpriseSetupView } from 'views/ClientSetup/Enterprise';
import { EnterpriseSetupFinance, EnterpriseSetupFinanceView } from 'views/ClientSetup/Enterprise/finances';
import { Department, DigitalSigning } from 'views/Department';
import { EmployeeChecklist } from 'views/Employee/EmployeeChecklist';
import { EmployeeContract, EmployeeContractManagement } from 'views/Employee/EmployeeContract';
import { EmployeeList } from 'views/Employee/EmployeeList';
import { EmployeeView } from 'views/Employee/EmployeeView';
import { EnterpriseChecklist } from 'views/Enterprise/EnterpriseChecklist';
import { EnterpriseContract, EnterpriseContractManagement } from 'views/Enterprise/EnterpriseContract';
import { EnterpriseList } from 'views/Enterprise/EnterpriseList';
import { EnterpriseProposal } from 'views/Enterprise/EnterpriseProposal';
import { EnterpriseServiceFee, EnterpriseServiceFeeView } from 'views/Enterprise/EnterpriseServiceFee';
import { EnterpriseView } from 'views/Enterprise/EnterpriseView';
import { CreditScore, CreditScoreView } from 'views/GeneralSetup';
import { PromotionList, PromotionView } from 'views/GeneralSetup/Promotion';
import { CostAndPrice } from 'views/GeneralSetup/SettingCost';
import { TransPayingList } from 'views/Transaction/TransPaying/TransPayingList';
import { TransPayingView } from 'views/Transaction/TransPaying/TransPayingView';
import { TransSettlementList } from 'views/Transaction/TransSettlement/TransSettlementList';
import { TransWaitSettlementView } from 'views/Transaction/TransSettlement/TransSettlementView';
import { TransRejectList } from 'views/Transaction/TransReject/TransRejectList';
import { TransRejectView } from 'views/Transaction/TransReject/TransRejectView';
import { EnterpriseTransHis } from 'views/Transaction/Enterprise';
import { EmployeeTransHis } from 'views/Transaction/Employee';
import { TransHis } from 'views/Transaction/TransHis';
import { Profile } from 'views/Profile';
import { HRManagement } from 'views/HR';
import { AttendanceManagement } from 'views/Attendance';
import Payroll from 'views/Payroll';
import { Home } from 'views/Home';
import { TransOverdueView } from 'views/Transaction/TransOverdue/TransOverdueView';
import { TransOverdueList } from 'views/Transaction/TransOverdue/TransOverdueList';
import { EnterpriseNotice } from 'views/Enterprise/EnterpriseNotice';
import { usePermission } from 'hooks';

// import * as usePermission from 'hooks';

type RouteType = {
  path: string;
  url?: (query: any) => string;
  name?: string | JSX.Element;
  element: JSX.Element;
  requiredPermission: boolean;
};


type PrivateRouteType = {
  [key: string]: RouteType;
};
// const { requreUserPermission,ENTERPRISE_VIEW,EMPLOYEE_VIEW } = usePermission();
const privateRoute: PrivateRouteType = {
  home: {
    path: '/home',
    name: <Trans>Dashboard</Trans>,
    element: <Home />,
    requiredPermission: true
  },
  profile: {
    path: '/profile',
    name: <Trans>Profile</Trans>,
    element: <Profile />,
    requiredPermission: true
  },
  client: {
    path: '/client-management',
    name: <Trans>Client Management</Trans>,
    element: <Fragment />,
    requiredPermission: true
  },
  enterpriseClient: {
    path: '/client-management/enterprise',
    name: <Trans>Enterprise</Trans>,
    element: <EnterpriseList />,
    requiredPermission: true
  },
  enterpriseServiceFee: {
    path: '/enterprise/service-fee',
    name: <Trans>Enterprise Service Fee</Trans>,
    element: <EnterpriseServiceFee />,
    requiredPermission: true
  },
  enterpriseServiceFeeView: {
    path: '/enterprise/service-fee/:id',
    url: ({ id }) => `/enterprise/service-fee/${id}`,
    element: <EnterpriseServiceFeeView />,
    requiredPermission: true
  },
  employeeClient: {
    path: '/client-management/employee',
    name: <Trans>Employee</Trans>,
    element: <EmployeeList />,
    requiredPermission:  true
  },

  enterpriseClientView: {
    path: '/client-management/enterprise/:id',
    url: ({ id }) => `/client-management/enterprise/${id}`,
    element: <EnterpriseView />,
    requiredPermission: true
  },
  enterpriseAssignmentChecklist: {
    path: '/client-management/enterprise/:id/assignment-checklist',
    url: ({ id }) => `/client-management/enterprise/${id}/assignment-checklist`,
    element: <EnterpriseChecklist />,
    requiredPermission: true
  },
  enterpriseAssignmentProposal: {
    path: '/client-management/enterprise/:id/assignment-proposal',
    url: ({ id }) => `/client-management/enterprise/${id}/assignment-proposal`,
    element: <EnterpriseProposal />,
    requiredPermission: true
  },
  enterpriseContract: {
    path: '/client-management/enterprise/:id/contract',
    url: ({ id }) => `/client-management/enterprise/${id}/contract`,
    element: <EnterpriseContract />,
    requiredPermission: true
  },

  employeeClientView: {
    path: '/client-management/employee/:id',
    url: ({ id }) => `/client-management/employee/${id}`,
    element: <EmployeeView />,
    requiredPermission: true
  },
  employeeAssignmentChecklist: {
    path: '/client-management/employee/:id/assignment-checklist',
    url: ({ id }) => `/client-management/employee/${id}/assignment-checklist`,
    element: <EmployeeChecklist />,
    requiredPermission: true
  },
  employeeContract: {
    path: '/client-management/employee/:id/contract',
    url: ({ id }) => `/client-management/employee/${id}/contract`,
    element: <EmployeeContract />,
    requiredPermission: true
  },

  clientSetup: {
    path: '/client-setup',
    name: <Trans>Client Setup</Trans>,
    element: <Fragment />,
    requiredPermission: true
  },
  enterpriseSetup: {
    path: '/client-setup/enterprise',
    name: <Trans>Enterprise</Trans>,
    element: <EnterpriseSetupList />,
    requiredPermission: true
  },
  enterpriseSetupView: {
    path: '/client-setup/enterprise/:id',
    url: ({ id }) => `/client-setup/enterprise/${id}`,
    element: <EnterpriseSetupView />,
    requiredPermission: true
  },
  enterpriseSetupFinance: {
    path: '/client-setup/enterprise/:id/finance',
    url: ({ id }) => `/client-setup/enterprise/${id}/finance`,
    element: <EnterpriseSetupFinance />,
    requiredPermission: true
  },
  enterpriseSetupFinanceView: {
    path: '/client-setup/enterprise/:id/finance/:fnId',
    url: ({ id, fnId }) => `/client-setup/enterprise/${id}/finance/${fnId}`,
    element: <EnterpriseSetupFinanceView />,
    requiredPermission: true
  },

  employeeSetup: {
    path: '/client-setup/employee',
    name: <Trans>Employee</Trans>,
    element: <EmployeeSetupList />,
    requiredPermission: true
  },

  employeeSetupView: {
    path: '/client-setup/employee/:id',
    url: ({ id }) => `/client-setup/employee/${id}`,
    element: <EmployeeSetupView />, requiredPermission: true
  },

  department: {
    path: '/department-management',
    name: <Trans>Department Management</Trans>,
    element: <Fragment />, requiredPermission: true
  },
  departmentAssignment: {
    path: '/department-management/assignment',
    name: <Trans>Assignment</Trans>,
    element: <Department />, requiredPermission: true
  },
  departmentDigitalSigning: {
    path: '/department-management/digital-signing',
    name: <Trans>Digital Signing</Trans>,
    element: <DigitalSigning />, requiredPermission: true
  },

  account: {
    path: '/account',
    name: <Trans>Account Management</Trans>,
    element: <Fragment />, requiredPermission: true
  },
  accountEnterprise: {
    path: '/account/enterprise',
    name: <Trans>Company Information</Trans>,
    element: <AccountEnterprise />, requiredPermission: true
  },
  accountEmployee: {
    path: '/account/employee',
    name: <Trans>Employee Information</Trans>,
    element: <AccountEmployee />, requiredPermission: true
  },
  accountInformation: {
    path: '/account/information',
    name: <Trans>Account Information</Trans>,
    element: <AccountInformation />, requiredPermission: true
  },

  generalSetup: {
    path: '/general-setup',
    name: <Trans>General Setup</Trans>,
    element: <Fragment />, requiredPermission: true
  },
  costAndPriceSetup: {
    path: '/general-setup/cost-and-price',
    name: <Trans>Cost & Price</Trans>,
    element: <CostAndPrice />, requiredPermission: true
  },
  promotionSetup: {
    path: '/general-setup/promotion',
    name: <Trans>Promotion</Trans>,
    element: <PromotionList />, requiredPermission: true
  },
  promotionSetupView: {
    path: '/general-setup/promotion/:id',
    url: ({ id }) => `/general-setup/promotion/${id}`,
    element: <PromotionView />, requiredPermission: true
  },
  creditScoreSetup: {
    path: '/general-setup/credit-score',
    name: <Trans>Credit Score</Trans>,
    element: <CreditScore />, requiredPermission: true
  },
  creditScoreView: {
    path: '/general-setup/credit-score/:id',
    url: ({ id }) => `/general-setup/credit-score/${id}`,
    element: <CreditScoreView />, requiredPermission: true
  },

  hrManagement: {
    path: '/hr-management',
    name: <Trans>HR Management</Trans>,
    element: <HRManagement />, requiredPermission: true
  },
  attendanceManagement: {
    path: '/attendance-management',
    name: <Trans>Attendance Management</Trans>,
    element: <AttendanceManagement />, requiredPermission: true
  },
  enterpriseNotices: {
    path: '/notices',
    name: <Trans>Notice Management</Trans>,
    element: <EnterpriseNotice />, requiredPermission: true
  },
  transactionManagement: {
    path: '/transaction-management',
    name: <Trans>Transaction Management</Trans>,
    element: <Fragment />, requiredPermission: true
  },
  transactionPaying: {
    path: '/transaction-management/paying',
    name: <Trans>Transaction Paying</Trans>,
    element: <TransPayingList />, requiredPermission: true
  },
  transactionPayingView: {
    path: '/transaction-management/paying/:id',
    url: ({ id }) => `/transaction-management/paying/${id}`,
    element: <TransPayingView />, requiredPermission: true
  },
  transactionSettlement: {
    path: '/transaction-management/settlement',
    name: <Trans>Transaction Settlement</Trans>,
    element: <TransSettlementList />, requiredPermission: true
  },
  transactionWaitSettlementView: {
    path: '/transaction-management/settlement/wait/:id',
    url: ({ id }) => `/transaction-management/settlement/wait/${id}`,
    element: <TransWaitSettlementView />, requiredPermission: true
  },
  transactionReject: {
    path: '/transaction-management/reject',
    name: <Trans>Transaction Rejected</Trans>,
    element: <TransRejectList />, requiredPermission: true
  },
  transactionRejectView: {
    path: '/transaction-management/reject/:id',
    url: ({ id }) => `/transaction-management/reject/${id}`,
    element: <TransRejectView />, requiredPermission: true
  },
  transactionOverdue: {
    path: '/transaction-management/overdue',
    name: <Trans>Overdue Management</Trans>,
    url: ({ id }) => `/transaction-management/overdue`,
    element: <TransOverdueList />, requiredPermission: true
  },
  transactionOverdueView: {
    path: '/transaction-management/overdue/:id',
    url: ({ id }) => `/transaction-management/overdue/${id}`,
    element: <TransOverdueView />, requiredPermission: true
  },
  transactionHis: {
    path: '/transaction-management/phis',
    name: <Trans>Transaction History</Trans>,
    element: <TransHis />, requiredPermission: true
  },

  // Employee
  payrollApplication: {
    path: '/payroll',
    name: <Trans>Payroll Application</Trans>,
    element: <Payroll />, requiredPermission: true
  },
  transactionHistory: {
    path: '/transaction-history/emhis',
    name: <Trans>Transaction History</Trans>,
    element: <EmployeeTransHis />, requiredPermission: true
  },
  transactionEnterpriseHis: {
    path: '/transaction-management/enhis',
    name: <Trans>Transaction History</Trans>,
    element: <EnterpriseTransHis />, requiredPermission: true
  },
  entContractManagement: {
    path: '/encontract-management',
    name: <Trans>Contract Management</Trans>,
    element: <EnterpriseContractManagement />, requiredPermission: true
  },
  empContractManagement: {
    path: '/eecontract-management',
    name: <Trans>Contract Management</Trans>,
    element: <EmployeeContractManagement />, requiredPermission: true
  },

  accountManagement: {
    path: '/account-management',
    name: <Trans>Account Management</Trans>,
    element: <Fragment />, requiredPermission: true
  },
};

export default privateRoute;

