import { t, Trans } from '@lingui/macro';

import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { EnterpriseTrans, EnterpriseGuarantee, EnterpriseFee } from 'views/Enterprise/EnterpriseNotice/components';

const EnterpriseNotice = () => {
  const tabs = [
    { code: 'trans', label: t`TRANSACTION NOTICE`, component: <EnterpriseTrans /> },
    { code: 'guarantee', label: t`GUARANTEE NOTICE`, component: <EnterpriseGuarantee /> },
    { code: 'fee', label: t`	FEE PAYMENT NOTICE`, component: <EnterpriseFee /> },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>NOTICE MANAGEMENT</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default EnterpriseNotice;
