import { Trans } from '@lingui/macro';
import { Container, Typography, Button, Grid, Dialog } from '@mui/material';
import { StaticLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';
import ContactPopup from './ContactPopup';
import { Fragment, useState, useEffect } from 'react';

const AboutUs = () => {
  const userLocale = useActiveLocale();
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  const [isShown, setIsShown] = useState(false);
  function submitContact() {
    setOpenSelectBank(true)
  }
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  // function changeBackground(e) {
  //   e.target.style.background = 'red';
  // }
  return (
    <StaticLayout>
      {/* <Container className='py-20'>
        <Typography className='font-black text-[3rem]'><Trans>About Us</Trans></Typography>
      </Container> */}
      <div data-aos="fade-up" style={{ backgroundColor: '#DFEDED' }}>
        <Container className='py-20' style={{ textAlign: 'center' }}>
          <div style={{ fontSize: 50, fontWeight: 700, }}>{userLocale === 'vi-VN' ? <Trans><span style={{ color: "#1C2B4D" }}>ỨNG LƯƠNG CÔNG NGHỆ</span> <span style={{ color: "#29A6DC" }}>SCP</span></Trans> : <Trans><span style={{ color: "#29A6DC" }}>SCP</span> <span style={{ color: "#1C2B4D" }}>SALARY ADVANCE SERVICE</span></Trans>}</div>
          <div style={{ fontSize: 25, color: "#415E6F" }}><Trans>Give you real-time access to your earned salaries anytime, anywhere, instantly</Trans>.</div>
          <div style={{ marginTop: 15 }}><Button onClick={submitContact} variant="contained" color="primary" style={{ backgroundColor: "#29A6DC" }}><Trans>Contact Us</Trans>!</Button></div>
        </Container>
        <Container className='py-10' style={{ position: "relative", textAlign: 'center' }}>
          <img data-aos="zoom-in" style={{ width: 250, position: "absolute", right: 0, bottom: 0 }} src={require('assets/images/pic3-preview.png')} />
        </Container>

      </div>
      <div>
        <Container className='py-20' style={{ position: 'relative' }}>
          <img data-aos="zoom-in" style={{ width: 330, position: "absolute", left: "-3%", top: "-25%" }} src={require('assets/images/pic1-preview.png')} />
          <div style={{ margin: 30, borderColor: "#0FB7AD", borderWidth: 1.33, borderRadius: 15, display: 'flex' }}>
            <div style={{ width: "75%", padding: 40, paddingBottom: 100, paddingTop: 45, position: 'relative', }}>
              <div style={{ fontSize: 34, color: "#1C2B4D" }}><Trans>Explore new potentials</Trans></div>
              <div style={{ fontSize: 16, lineHeight: "28px", textAlign: "justify" }}><Trans>SCP Technology Salary Advance Platform Co., Ltd. - a member of SCP Group, is an enterprise leading the advance salary service for employees based on debt trading license – called Income Transfer.</Trans></div>
              <div style={{ fontSize: 16, lineHeight: "28px", textAlign: "justify" }}><Trans>Founded in March 2021, SCP has affirmed its position and provided salary advance services for employees working at enterprises such as Viet Hung Corporation, DK Company, ...</Trans></div>
            </div>
            <div style={{ width: "2%" }}></div>
            <div style={{ width: "23%", position: "relative" }}>
              <img style={{ position: "absolute", right: 20, bottom: 0, height: 360 }} src={require('assets/images/pic2.png')} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: "50%", borderColor: "#0FB7AD", borderWidth: 1.33, borderRadius: 15, marginLeft: 30, marginRight: 15, padding: 20, position: 'relative' }}>
              <div style={{ width: '70%', paddingBottom: 85 }}>
                <div style={{ fontSize: "28px", color: "#1C2B4D", fontWeight: 600, marginBottom: 15 }}><Trans>Our mission</Trans></div>
                <div style={{ textAlign: "justify" }}><Trans>Bring financial welfare solutions for employees with Enterprise.</Trans></div>
                <div style={{ textAlign: "justify" }}><Trans>Our mission is to help you build a financial awareness, healthy spending and be proactive in consumer psychology.</Trans></div>
              </div>
              {/* <div style={{width:'30%',position:'relative'}}> */}
              <img style={{ position: "absolute", right: 20, bottom: 10, width: "210px" }} src={require('assets/images/pic4.png')} />
              {/* </div> */}
            </div>
            <div style={{ width: "50%", borderColor: "#0FB7AD", borderWidth: 1.33, borderRadius: 15, marginRight: 30, marginLeft: 15, padding: 20, position: 'relative' }}>
              <div style={{ fontSize: "28px", color: "#1C2B4D", fontWeight: 600, marginBottom: 15 }}><Trans>Our vision</Trans></div>
              <div style={{ width: "70%", textAlign: "justify" }}><Trans>We wish to shorten the payday gap & build an ideal online platform where every individual will be able to access innovative solutions for instant financial assistance.</Trans></div>
              <img style={{ position: "absolute", right: 20, bottom: 10, width: '200px' }} src={require('assets/images/pic5.png')} />
            </div>
          </div>
          <div style={{ margin: 30, borderColor: "#0FB7AD", borderWidth: 1.33, borderRadius: 15, display: 'flex' }}>
            <div style={{ width: "50%" }}>
              <div style={{ fontSize: "28px", color: "#1C2B4D", fontWeight: 600, marginBottom: 15, padding: "15px" }}><Trans>Learn more about SCP Service here</Trans></div>
            </div>
            <div style={{ width: "50%", marginLeft: 55, padding: 20 }}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/HZ_WpKhSdO0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ background: `url(${require('assets/images/pic6.png')})`, backgroundSize: 'cover' }}>
        <Container style={{ position: 'relative', paddingTop: "30px", paddingBottom: "5rem" }}>
          <div style={{ textAlign: 'center', color: 'white' }}>
            <div style={{ fontSize: 40 }}><Trans>OUR CORE VALUES</Trans></div>
            <div style={{ padding: "0px 25px" }}><Trans>Our mission is to make pay strategic and break boundaries. We've built that mission on five core values from which we develop our service, our technology and our business strategies. Our core values are a promise to our employees, customers and partners.</Trans></div>
          </div>
          <div style={{ display: 'flex', textAlign: 'center', marginTop: "35px" }}>
            <div style={{ width: '20%', backgroundColor: 'white', margin: "10px 20px", height: "360px", borderRadius: 10 }}>
              <div data-aos="flip-left" style={{ display: 'flex', height: "120px", justifyContent: "center", alignItems: 'center' }}><img src={require('assets/images/icon1.png')} /></div>
              <div style={{ color: "#1C2B4D", fontWeight: 600, fontSize: "20px" }}><Trans>Professional</Trans></div>
              <div style={{ padding: "20px 30px" }}><Trans>Serving all customers with integrity and respect, all in the pursuit of excellence</Trans></div>
            </div>
            <div style={{ width: '20%', backgroundColor: 'white', margin: "10px 20px", height: "360px", borderRadius: 10 }}>
              <div data-aos="flip-left" style={{ display: 'flex', height: "120px", justifyContent: "center", alignItems: 'center' }}><img src={require('assets/images/icon2.png')} /></div>
              <div style={{ color: "#1C2B4D", fontWeight: 600, fontSize: "20px" }}><Trans>Passion</Trans></div>
              <div style={{ padding: "20px 30px" }}><Trans>Delivering on our commitments with tenacity and enthusiasm</Trans></div>
            </div>
            <div style={{ width: '20%', backgroundColor: 'white', margin: "10px 20px", height: "360px", borderRadius: 10 }}>
              <div data-aos="flip-left" style={{ display: 'flex', height: "120px", justifyContent: "center", alignItems: 'center' }}><img src={require('assets/images/icon3.png')} /></div>
              <div style={{ color: "#1C2B4D", fontWeight: 600, fontSize: "20px" }}><Trans>Empowered</Trans></div>
              <div style={{ padding: "20px 30px" }}><Trans>Encouraging our team to make responsible decisions and take accountability</Trans></div>
            </div>
            <div style={{ width: '20%', backgroundColor: 'white', margin: "10px 20px", height: "360px", borderRadius: 10 }}>
              <div data-aos="flip-left" style={{ display: 'flex', height: "120px", justifyContent: "center", alignItems: 'center' }}><img src={require('assets/images/icon4.png')} /></div>
              <div style={{ color: "#1C2B4D", fontWeight: 600, fontSize: "20px" }}><Trans>Innovation</Trans></div>
              <div style={{ padding: "20px 30px" }}><Trans>Transforming the status quo by constantly improving ourselves, our service and our technology</Trans></div>
            </div>
            <div style={{ width: '20%', backgroundColor: 'white', margin: "10px 20px", height: "360px", borderRadius: 10 }}>
              <div data-aos="flip-left" style={{ display: 'flex', height: "120px", justifyContent: "center", alignItems: 'center' }}><img src={require('assets/images/icon5.png')} /></div>
              <div style={{ color: "#1C2B4D", fontWeight: 600, fontSize: "20px" }}><Trans>Teamwork</Trans></div>
              <div style={{ padding: "20px 30px" }}><Trans>Achieving our vision through thoughtful collaboration with our customers and team members</Trans></div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className='py-20'>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%', textAlign: "center", fontSize: 35, color: "#1C2B4D" }}>
              <Trans>Why choose SCP?</Trans>
            </div>
            <div style={{ width: '50%' }}></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div data-aos="fade-right" style={{ width: '50%', display: 'flex', justifyContent: "center", margin: '30px 0px', }}>
              <img style={{ width: '70%' }} src={require('assets/images/pic7.jpg')} />
            </div>
            <div data-aos="fade-right" style={{ width: '50%' }}>
              <div style={{ padding: 15 }}>
                <div style={{ fontSize: 25, fontWeight: 700, color: "#333F50" }}><Trans>Exclusive Platform</Trans></div>
                <div style={{ marginTop: 15 }}><Trans>Advance both the earned working days and a part of future working days depending on the company’s policies</Trans></div>
              </div>
              <div style={{ padding: 15 }}>
                <div style={{ fontSize: 25, fontWeight: 700, color: "#333F50" }}><Trans>Fast & Secure</Trans></div>
                <div style={{ marginTop: 15 }}><Trans>With 24/7 payment gateway, all your transactions are paid quickly & user salary information are ensured totally.</Trans></div>
              </div>
              <div style={{ padding: 15 }}>
                <div style={{ fontSize: 25, fontWeight: 700, color: "#333F50" }}><Trans>100% Online transaction</Trans></div>
                <div style={{ marginTop: 15 }}><Trans>No hardcopies, no complicated process, you just need to provide and verify some information on our app to get an advance salary.</Trans></div>
              </div>
              <div style={{ padding: 15 }}>
                <div style={{ fontSize: 25, fontWeight: 700, color: "#333F50" }}><Trans>Diversified products</Trans></div>
                <div style={{ marginTop: 15 }}><Trans>Out of salary advance service, SCP always improves to provide customers  many products that bring high income and attractive interest rates.</Trans></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ background: `url(${require('assets/images/bg-investment.png')}) no-repeat` }}>
        <Container className='py-20'>
          <Grid container columnSpacing={8}>
            <Grid item md={7}>
              <img src={require('assets/images/static-investment-1.png')} data-aos='fade-right' />
            </Grid>
            <Grid item md={5} className='flex flex-col items-start justify-end pb-10'>
              <Typography className='font-black text-[3rem] text-white mb-4'>
                <Trans>Get your salary now!</Trans>
              </Typography>
              <Typography className='text-white mb-10'>
                <Trans>Contact SCP immediately to become a changemaker and financially proactive</Trans>
              </Typography>
              <Button onClick={submitContact} variant='contained' size='large' color='info' className='h-14 rounded-lg mx-auto'>
                <Trans>Contact SCP</Trans>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
    </StaticLayout>
    // <div className='bg-blue-200'>
    //   <Container className='py-10'>
    //     <div><Trans>SCP SALARY ADVANCE SERVICE</Trans></div>
    //   </Container>
    // </div>
  );
};

export default AboutUs;
